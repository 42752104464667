<template>
  <div>
    <div class="c-exp-box">
      <div class="c-exp-box-title">{{title}}</div>
      <el-progress type="dashboard" :percentage="percentage()" stroke-width="10" :format="format" :color="color"></el-progress>
      <div class="c-exp-box-step">{{tips}}</div>
      <div class="c-exp-box-prob" v-if="prob">成功率：{{_prob}}%</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExpList',
  props: {
    active: {
      type: Number
    },
    max: {
      type: Number
    },
    title: {
      type: String
    },
    color: {
      default: '#5B8DD7',
      type: String
    },
    tips: {
      default: '进行中/全部',
      type: String
    },
    prob: {
      type: [String, Number]
    }
  },
  computed: {
    _prob () {
      return this.prob.toFixed(2)
    }
  },
  created () {

  },
  data () {
    return {

    }
  },
  methods: {
    percentage () {
      return this.active / this.max * 100
    },
    format () {
      return this.active + '/' + this.max
    }
  }
}
</script>

<style lang="scss" scoped>
.c-exp-box{
  min-width: 100px;
  padding-bottom: 0;
  border-radius: 15px;
  border: 1px solid #DCDFE6;
  text-align: center;
  padding-bottom: 20px;
  transition: all .2s;
  .c-exp-box-title{
    font-size: 16px;
    line-height: 40px;
    font-weight: bold;
  }
  .c-exp-box-step{
    margin-top: -20px;
    color: #909399;
    font-size: 12px;
  }
  .c-exp-box-prob{
    color: #333;
    font-size: 12px;
  }
}
.c-exp-box:hover{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
