<template>
  <basic-container>
    <div class="exp-content">
      <div class="exp-date-block">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="时间">
            <el-date-picker
                v-model="date1"
                type="daterange"
                align="right"
                unlink-panels
                value-format="yyyy-mm-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="癌种">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="exp-wrap">
          <div class="exp-wrap-box" v-for="item in testList" :key="item">
            <expList :title="item.name" :active="item.success" :max="item.total" :prob="item.rate * 100" tips="成功/总计"/>
          </div>
      </div>
    </div>
    <el-divider></el-divider>
    <el-row style="margin-top: 20px;min-width: 800px;">
      <el-col :span="12">
        <div class="echart-title">{{title1}}</div>
        <el-form :inline="true" :model="formInline" style="text-align: center">
          <el-form-item>
            <el-date-picker
                v-model="date2"
                type="daterange"
                align="right"
                unlink-panels
                value-format="yyyy-mm-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select style="width:120px" v-model="value" placeholder="一年之内">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div ref="line1" class="chart1"></div>
      </el-col>
      <el-col :span="12">
        <div class="echart-title">{{title2}}</div>
        <el-form :inline="true" :model="formInline" style="text-align: center">
          <el-form-item>
            <el-date-picker
                v-model="date3"
                type="daterange"
                align="right"
                unlink-panels
                value-format="yyyy-mm-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select style="width:120px" v-model="value" placeholder="一年之内">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div ref="line2" class="chart1"></div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px;min-width: 800px;">
      <el-col :span="12">
        <div class="echart-title">{{title3}}</div>
        <el-form :inline="true" :model="formInline" style="text-align: center">
          <el-form-item>
            <el-date-picker
                v-model="date4"
                type="daterange"
                align="right"
                unlink-panels
                value-format="yyyy-mm-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select style="width:120px" v-model="value" placeholder="一年之内">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div ref="line3" class="chart1"></div>
      </el-col>
      <el-col :span="12">
        <div class="echart-title">{{title4}}</div>
        <el-form :inline="true" :model="formInline" style="text-align: center">
          <el-form-item>
            <el-date-picker
                v-model="date5"
                type="daterange"
                align="right"
                unlink-panels
                value-format="yyyy-mm-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select style="width:120px" v-model="value" placeholder="一年之内">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div ref="line4" class="chart1"></div>
      </el-col>
    </el-row>

  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { mapGetters } from 'vuex'
import expList from '@/components/view-comp/exp-list'
import { cultureRate, identifyRate, recoverRate, drugRate, statisticTags, getDefaultDate } from '@/api/exp/dashboard'
export default {
  mixins: [mixin],
  components: {
    expList
  },
  data () {
    return {
      value2: [],
      testList: [],
      title1: '',
      title2: '',
      title3: '',
      title4: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.exp_reagent_add,
        viewBtn: this.permission.exp_reagent_view,
        delBtn: this.permission.exp_reagent_delete,
        editBtn: this.permission.exp_reagent_edit
      }
    }
  },
  mounted () {
    this.defaultDate()
    this.getChart1()
    this.getChart2()
    this.getChart3()
    this.getChart4()
    this.getList()
  },
  methods: {
    onSubmit () {
      console.log(this.value2)
    },
    defaultDate () {
      getDefaultDate().then(res => {
        this.date1 = res.data
        this.date2 = res.data
        this.date3 = res.data
        this.date4 = res.data
        this.date5 = res.data
      })
    },
    getChart1 () {
      cultureRate().then(res => {
        this.inLine(this.getChartData(res.data, 'line'), 'line1')
        this.title1 = res.data[-1].name
        console.log()
      })
    },
    getChart2 () {
      identifyRate().then(res => {
        this.inLine(this.getChartData(res.data, 'bar'), 'line2')
        this.title2 = res.data[-1].name
        console.log()
      })
    },
    getChart3 () {
      recoverRate().then(res => {
        this.inLine(this.getChartData(res.data, 'line'), 'line3')
        this.title3 = res.data[-1].name
        console.log()
      })
    },
    getChart4 () {
      drugRate().then(res => {
        this.inLine(this.getChartData(res.data, 'line'), 'line4')
        this.title4 = res.data[-1].name
        console.log()
      })
    },
    getList () {
      statisticTags().then(res => {
        this.testList = res.data
        console.log(res)
      })
    },
    getChartData (data, type) {
      const legend = []
      let xAxis = []
      const series = []
      let name = []
      for (const i in data) {
        if (i === '-1') {
          xAxis = data[i].data
          name = data[i].name
        } else {
          legend.push(data[i].type)
          series.push({
            name: data[i].type,
            type,
            smooth: 0.4,
            itemStyle: {
              borderRadius: [50, 50, 0, 0]
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#a6cdff'
                }, {
                  offset: 1, color: '#fff'
                }],
                global: false
              }
            },
            data: data[i].data.map(item => {
              return (item * 100).toFixed(2)
            })
          })
        }
      }
      return { legend, xAxis, series, name }
    },
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    // 柱状图
    inLine ({ legend, xAxis, series, name }, refName) {
      const myChart = this.$echarts.init(this.$refs[refName])

      const option = {
        title: {
          left: 'center'
          // text: name
        },
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value) => `${value}%`
        },
        legend: {
          data: legend
        },
        grid: {
          left: '3%',
          right: '4%',
          top: 50,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis
        },
        yAxis: {
          type: 'value',
          max: 100,
          min: 0,
          axisLabel: {
            formatter: '{value} %'
          }
        },
        series
      }
      // 图标自适应
      const listener = function () {
        myChart.resize()
      }
      window.addEventListener('resize', listener)
      // 图标自适应
      myChart.setOption(option)
    }
  }
}
</script>
<style lang="scss">
.exp-list{
  margin-top: 20px;
}
.exp-wrap{
  display: flex;
  min-width: 800px;
  justify-content: space-between;
  .exp-wrap-box{
    width: 19%;
  }
}
.echart-title{
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  font-size: 18px;
}
.chart1{
  width: 100%;
  height: 300px;
}
</style>
